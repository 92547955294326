<script lang="ts">
import { defineComponent, ref, onMounted, computed, onUnmounted } from 'vue';
import FooterComponent from './components/FooterComponent.vue';
import axios from 'axios';

interface Website {
  url: string;
  name: string;
  status: string;
  checkedAt: string;
  certificateValid: boolean;
}

interface GitHubIssue {
  title: string;
  body: string;
  url: string;
  status: string;
  updatedAt: string;
}

export default defineComponent({
  name: 'App',
  components: {
    FooterComponent,
  },
  setup() {
    const websites = ref<Website[]>([]);
    const githubIssues = ref<GitHubIssue[]>([]);
    const currentPage = ref(1);
    const issuesPerPage = 5;

    // Mapping URLs to custom names
    const websiteNames: Record<string, string> = {
      'https://www.efficientether.co.uk': 'EfficientEther Website',
      'https://www.etherassist.ai': 'EtherAssist Website',
      'https://api.etherassist.ai': 'EtherAssist API',
      'https://app.etherassist.ai': 'EtherAssist WebApp',
    };

    const formatDate = (dateString: string) => {
      const date = new Date(dateString);
      return date.toLocaleString();
    };

    const openIssue = (url: string) => {
      window.open(url, '_blank');
    };

    // Get the API token from environment variables
    const apiToken = process.env.VUE_APP_API_TOKEN;
    const backend = process.env.VUE_APP_API_URL;

    // Create an Axios instance with default headers containing the token
    const axiosInstance = axios.create({
      baseURL: backend,
      headers: {
        Authorization: `Bearer ${apiToken}`,
      },
    });

    const fetchData = async () => {
      try {
        const statusResponse = await axiosInstance.get('status');
        const newWebsites = statusResponse.data.map((site: Website) => ({
          ...site,
          name: websiteNames[site.url] || 'Unknown Service',
        }));
        websites.value = newWebsites;
      } catch (error) {
        console.error('Failed to fetch website status data:', error);
      }
    };

    const fetchGitHubIssues = async () => {
      try {
        const response = await axiosInstance.get('github/issues');
        githubIssues.value = response.data;
      } catch (error) {
        console.error('Failed to fetch GitHub issues:', error);
      }
    };

    let refreshIntervalId: number;
    let gitHubIssuesIntervalId: number;

    onMounted(async () => {
      await fetchGitHubIssues();
      await fetchData();

      const threeMinutesInMilliseconds = 180000;
      refreshIntervalId = window.setInterval(fetchData, threeMinutesInMilliseconds);
      gitHubIssuesIntervalId = window.setInterval(fetchGitHubIssues, threeMinutesInMilliseconds);
    });

    onUnmounted(() => {
      clearInterval(refreshIntervalId);
      clearInterval(gitHubIssuesIntervalId);
    });

    return {
      websites,
      githubIssues: computed(() => 
        githubIssues.value.slice(
          (currentPage.value - 1) * issuesPerPage,
          currentPage.value * issuesPerPage
        )
      ),
      formatDate,
      openIssue,
      currentPage,
      nextPage: () => {
        if (currentPage.value * issuesPerPage < githubIssues.value.length) {
          currentPage.value++;
        }
      },
      prevPage: () => {
        if (currentPage.value > 1) {
          currentPage.value--;
        }
      },
      totalPages: computed(() => Math.ceil(githubIssues.value.length / issuesPerPage)),
    };
  },
});
</script>

<template>
  <div id="app">
    <div class="main-wrapper status">
      <div class="container">
        <img class="status-logo" src="./assets/images/logo.svg" alt="logo">

        <h1 class="status-title">Status Page</h1>

        <!-- About section -->
        <section class="about-section">
          <h2>About</h2>
          <p>Welcome to the Status Page of EfficientEther Ltd. This site provides updates and historical data regarding
            the availability and performance of all our Software as a Service (SaaS) products and Web Services.</p>
        </section>

        <!-- Website status table -->
        <div class="table-responsive">
          <table class="status-table">
            <thead>
              <tr>
                <th>Service</th>
                <th>Status</th>
                <th>Last Checked</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="website in websites" :key="website.url">
                <td>
                  <a :href="website.url" target="_blank">{{ website.name }}</a>
                </td>
                <td>
                  <div class="status-table-icon"
                    :class="{ 'status-table-icon-up': website.status === 'up', 'status-table-icon-down': website.status === 'down' }">
                    {{ website.status }}
                  </div>
                </td>
                <td>{{ formatDate(website.checkedAt) }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- GitHub issues section -->
        <div class="issues-container">
          <h2>Status History</h2>

          <div v-for="issue in githubIssues" :key="issue.url" class="issue-card" @click="openIssue(issue.url)">
            <div class="issue-card-wrapper">
              <h3>{{ issue.title }}</h3>
              <p v-if="issue.body">{{ issue.body }}</p>
            </div>
            <p class="issue-card-status"
              :class="{ 'issue-card-status-open': issue.status === 'open', 'issue-card-status-closed': issue.status === 'closed' }">
              Status: {{ issue.status }}
            </p>
            <p>Last Updated: {{ formatDate(issue.updatedAt) }}</p>
          </div>

          <!-- Pagination controls -->
          <div class="pagination">
            <button @click="prevPage" :disabled="currentPage === 1">Prev</button>
            <span>Page {{ currentPage }} of {{ totalPages }}</span>
            <button @click="nextPage" :disabled="currentPage >= totalPages">Next</button>
          </div>
        </div>
      </div>

      <FooterComponent />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import './assets/scss/variables.scss';

.status {
  &-logo {
    margin-top: 50px;
    max-height: 80px;

    @media (max-width: $breakpoint992) {
      margin-top: 20px;
      max-height: 60px;
    }
  }

  &-title {
    font-family: $fontOpenSans;
    font-size: 32px;
    font-weight: 700;
    margin: 50px 0 40px;

    @media (max-width: $breakpoint992) {
      font-size: 28px;
      margin: 40px 0 30px;
    }

    @media (max-width: $breakpoint576) {
      font-size: 26px;
    }
  }

  .about-section {
    padding-bottom: 35px;
    border-bottom: 2px solid $white;
    margin-bottom: 45px;

    @media (max-width: $breakpoint576) {
      padding-bottom: 25px;
      margin-bottom: 35px;
    }

    h2 {
      font-family: $fontOpenSans;
      font-size: 26px;
      font-weight: 700;
      margin-bottom: 10px;

      @media (max-width: $breakpoint992) {
        font-size: 24px;
      }

      @media (max-width: $breakpoint576) {
        font-size: 22px;
      }
    }

    p {
      font-size: 16px;
      margin: 0;

      @media (max-width: $breakpoint992) {
        font-size: 14px;
      }
    }
  }

  &-table {
    width: 100%;
    border: 1px solid $white;

    thead {
      background-color: $pink;
      font-family: $fontOpenSans;
      font-size: 22px;
      font-weight: 700;
      border-bottom: 1px solid $white;

      @media (max-width: $breakpoint992) {
        font-size: 20px;
      }

      th {
        padding: 10px 16px;

        @media (max-width: $breakpoint992) {
          padding: 10px 12px;
        }
      }
    }

    tbody {
      background-color: rgba(244, 244, 244, 0.2);
      font-size: 16px;

      @media (max-width: $breakpoint992) {
        font-size: 14px;
      }

      tr {
        border-bottom: 1px solid $white;
      }

      td {
        padding: 8px 16px;

        @media (max-width: $breakpoint992) {
          padding: 8px 12px;
        }

        a {
          color: $white;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }

        .status-table-icon {
          text-transform: capitalize;
          display: flex;
          align-items: center;
          gap: 8px;

          &::after {
            margin-bottom: -5px;
          }

          &.status-table-icon-up {
            &::after {
              content: url('data:image/svg+xml,<svg width="30" height="23" viewBox="0 0 30 23" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15 23H0L15 8.7174e-07L30 23H15Z" fill="%236EFF1A"/></svg>');
            }
          }

          &.status-table-icon-down {
            &::after {
              content: url('data:image/svg+xml,<svg width="30" height="23" viewBox="0 0 30 23" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15 0L30 0L15 23L1.00571e-06 0L15 0Z" fill="%23FF8939"/></svg>');
            }
          }
        }
      }
    }
  }

  .issues-container {
    margin-top: 45px;
    border-top: 2px solid $white;
    padding-top: 35px;
    margin-bottom: 80px;

    @media (max-width: $breakpoint768) {
      margin-bottom: 60px;
    }

    @media (max-width: $breakpoint576) {
      margin-top: 35px;
      padding-top: 25px;
      margin-bottom: 50px;
    }

    h2 {
      font-family: $fontOpenSans;
      font-size: 26px;
      font-weight: 700;
      margin-bottom: 20px;

      @media (max-width: $breakpoint992) {
        font-size: 24px;
      }

      @media (max-width: $breakpoint576) {
        font-size: 22px;
        margin-bottom: 16px;
      }
    }

    .issue-card {
      background-color: $blue;
      border: 1px solid $white;
      border-radius: 7px;
      display: grid;
      grid-template-columns: 55% 1fr 1.6fr;
      gap: 15px;
      align-items: center;
      padding: 18px 24px;
      margin-bottom: 20px;
      cursor: pointer;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;

      @media (max-width: $breakpoint992) {
        padding: 16px 20px;
        margin-bottom: 16px;
      }

      @media (max-width: $breakpoint576) {
        padding: 12px;
        grid-template-columns: 1fr;
      }

      &-wrapper {
        display: flex;
        flex-direction: column;
        overflow: hidden;

        h3 {
          font-family: $fontOpenSans;
          font-size: 22px;
          font-weight: 700;
          margin: 0;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;

          @media (max-width: $breakpoint992) {
            font-size: 20px;
          }

          @media (max-width: $breakpoint576) {
            font-size: 18px;
          }
        }

        p {
          margin-top: 10px !important;
        }
      }

      &-status {
        text-transform: capitalize;
        display: flex;
        align-items: center;
        gap: 8px;

        &::after {
          margin-top: -10px;
          transform: rotate(180deg);
        }

        &.issue-card-status-open {
          &::after {
            content: url('data:image/svg+xml,<svg width="30" height="23" viewBox="0 0 30 23" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15 0L30 0L15 23L1.00571e-06 0L15 0Z" fill="%23FF8939"/></svg>');
          }
        }

        &.issue-card-status-closed {
          &::after {
            content: url('data:image/svg+xml,<svg width="30" height="23" viewBox="0 0 30 23" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15 23H0L15 8.7174e-07L30 23H15Z" fill="%236EFF1A"/></svg>');
          }
        }
      }

      p {
        font-size: 16px;
        margin: 0;

        @media (max-width: $breakpoint992) {
          font-size: 14px;
        }
      }
    }
  }

  .pagination {
    margin-top: 50px;
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2px;
    width: 100%;
    max-width: 100%;

    @media (max-width: $breakpoint992) {
      margin-top: 35px;
    }

    &>* {
      display: flex;
      align-items: center;
      padding: 6px 10px;
      background-color: white;
      color: $black;
      font-size: 16px;
      font-weight: 600;
      transition: all .15s ease;
      cursor: pointer;
      border: 0;

      @media (max-width: $breakpoint576) {
        font-size: 14px;
      }

      &:first-child,
      &:last-child {
        &:hover {
          color: $blue;
        }

        &:disabled {
          opacity: 0.8;
          color: $gray;
        }
      }

      &:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }

      &:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
  }
}
</style>
