<template>
  <footer>
    <div class="footer-top">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 align-self-center">
            <div class="content-wrapper">
              <div class="logo-wrapper">
                <a href="https://www.efficientether.co.uk/" class="footer-logo">
                  <img src="../assets/images/icons/footer-logo.svg" alt="footer-logo">
                </a>
              </div>

              <p>Efficient Ether: Your partner in optimised cloud management. Revolutionising the way businesses
                interact
                with cloud computing. Harness the power of the cloud and propel your business to success with us.</p>

              <div class="social-links">
                <a href="https://www.youtube.com/channel/UClx3QuhoOdWrGSPbihiXpag">
                  <img src="../assets/images/icons/youtube.svg" alt="youtube">
                </a>

                <a href="https://twitter.com/EfficientEther">
                  <img src="../assets/images/icons/twitter.svg" alt="twitter">
                </a>

                <a href="https://www.linkedin.com/company/efficientether">
                  <img src="../assets/images/icons/linkedin.svg" alt="linkedin">
                </a>
              </div>

              <div class="microsoft">
                <img src="../assets/images/microsoft-footer-img.png" alt="microsoft-footer-img"
                  class="microsoft-footer-img">
              </div>
            </div>
          </div>

          <div class="col-lg-4 align-self-center">
            <ul class="footer-menu">
              <li>
                <a href="https://www.efficientether.co.uk/">Home</a>
              </li>
              <li><a href="https://www.efficientether.co.uk/#it-augmented">About</a></li>
              <li><a href="https://www.efficientether.co.uk/#get-started-now">Get Started</a></li>
              <li><a href="https://www.efficientether.co.uk/#for-more-info">Contact</a></li>
              <li>
                <a href="https://www.efficientether.co.uk/blog">Blog</a>
              </li>
            </ul>
          </div>

          <div class="col-lg-4 align-self-end">
            <div class="footer-right">
              <div class="footer-contact">
                <h3>Contact</h3>
                <p>Tel: <a href="tel:+4402038220072">+44 (0) 2038220072</a><br>
                  Email: <a href="mailto:info@efﬁcientether.co.uk">info@efficientether.co.uk</a></p>
              </div>

              <div class="certs">
                <h4>Certifications</h4>

                <div class="certs-images">
                  <img src="../assets/images/cyber-essentials.webp" height=72 alt="Cyber Essentials Certified">
                  <img src="../assets/images/iso-1.png" alt="ISO 27001 Approved">
                  <img src="../assets/images/iso-2.png" alt="ISO 9001 Approved">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bottom-bar">
      <div class="container">
        <div class="d-flex align-items-center justify-content-center flex-wrap text-center">
          <span>©2024 EfficientEther - All rights reserved</span>
          <span>|</span>
          <span>
            <a href="https://www.efficientether.co.uk/cookies">Cookies</a>
          </span>
          <span>|</span>
          <span>
            <a href="https://www.efficientether.co.uk/privacy">Privacy Policy</a>
          </span>
        </div>
        <span class="mt-1">"EtherAssist®" and "EfficientEther®" are registered trademarks of EfficientEther Ltd.</span>
      </div>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
@import '../assets/scss/variables.scss';

footer {
  margin-top: auto;

  .footer-top {
    background: #282828;
    padding: 40px 0 30px;

    .content-wrapper {
      @media screen and (max-width: $breakpoint992) {
        text-align: center;
        margin-bottom: 36px;
      }

      .logo-wrapper {
        margin: 0 0 20px;

        a.footer-logo {
          display: inline-block;
          text-align: center;

          img {
            height: 70px;
          }
        }
      }

      p {
        color: $white;
        margin: 0 0 16px;
        font-size: 14px;
        padding-right: 50px;
      }

      .social-links {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-bottom: 16px;

        @media (max-width: $breakpoint992) {
          justify-content: center;
        }

        a {
          img {
            width: 30px;
            height: 30px;
            object-fit: contain;
          }
        }
      }
    }

    ul.footer-menu {
      text-align: center;
      padding: 0;
      margin: 0 0 36px;

      li {
        display: inline-block;

        &:not(&:last-of-type) {
          margin: 0 20px 0 0;
        }

        a {
          color: $white;
          font-size: 16px;
          text-decoration: none;
        }
      }
    }

    .footer-right {
      width: fit-content;
      margin-left: auto;

      @media (max-width: $breakpoint992) {
        margin: 0 auto;
      }
    }

    .footer-contact {
      width: fit-content;

      @media (max-width: $breakpoint992) {
        width: 100%;
        margin: 0;
        text-align: center;
      }

      h3 {
        font-family: $fontOpenSans;
        font-size: 30px;
        font-weight: 700;
        margin-bottom: 16px;

        @media (max-width: $breakpoint992) {
          font-size: 24px;
        }

        @media (max-width: $breakpoint576) {
          font-size: 20px;
        }
      }

      p {
        font-size: 18px;
        margin-bottom: 48px;

        @media (max-width: $breakpoint992) {
          font-size: 16px;
        }

        a {
          color: $white;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .microsoft {
      .microsoft-footer-img {
        width: 100%;
        max-width: 180px;
        height: auto;
      }
    }

    .iso {
      @media (max-width: $breakpoint992) {
        text-align: center;
      }

      h4 {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 16px;

        @media (max-width: $breakpoint992) {
          font-size: 20px;
        }

        @media (max-width: $breakpoint576) {
          font-size: 18px;
        }
      }

      &-images {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;

        img {
          height: 100%;
          max-height: 72px;
        }
      }
    }
  }

  .bottom-bar {
    padding: 20px 0 40px;
    background: #282828;

    span {
      display: block;
      color: $white;
      text-align: center;
      font-size: 14px;
      padding: 0 4px;

      a {
        color: $white;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
</style>